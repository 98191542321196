import { Translate } from '@hanssens/ht-translate';
import * as React from 'react';
import { IUser } from '../pages/Main';

import Property from './shared/Property';

interface ICustomerInfoProps {
    user: IUser | null;
}

const CustomerInfo: React.FC<ICustomerInfoProps> = ({ user }) => {
    /***********************************************************************
     * Render
     ***********************************************************************/
    return (
        <div className='d-flex flex-column'>
            <Property label={<Translate id='main.firstname' />} value={user?.firstName} />
            <Property label={<Translate id='main.surname' />} value={user?.surName} />
            <Property label={<Translate id='main.mobileNumber' />} value={user?.mobileNumber} />
        </div>
    );
};

export default CustomerInfo;
