import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { TranslateProvider } from '@hanssens/ht-translate';

// Import app
import App from './pages/App';

// css
import './css/main.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    // <React.StrictMode>
    <TranslateProvider languages={['en', 'nl']}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </TranslateProvider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
