import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useTranslate } from '@hanssens/ht-translate';

import Main from '../pages/Main';

import mainTranslations from '../translations/main.json';

interface IMainRouterProps {}

const MainRouter: React.FC<IMainRouterProps> = (props) => {
    useTranslate(mainTranslations);

    /***********************************************************************
     * Render
     ***********************************************************************/
    return (
        <Routes>
            {/* General */}
            <Route path='/*' element={<Main />} />

            {/* REDIRECT */}
            <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
    );
};

export default MainRouter;
