import * as React from 'react';

interface ICustomerLogoProps {
    logo: string | null;
}

const CustomerLogo: React.FC<ICustomerLogoProps> = ({ logo }) => {
    /***********************************************************************
     * Render
     ***********************************************************************/
    let content = <i className='fas fa-user-circle fa-5x text-dark' />;
    if (logo) {
        content = <img src={`data:image/png;base64,${logo}`} alt='avatar' />;
    }

    return (
        <div className='border-green mb-3'>
            <div className='border-white'>
                <div className='avatar'>{content}</div>
            </div>
        </div>
    );
};

export default CustomerLogo;
