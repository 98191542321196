import * as React from 'react';

import MainRouter from '../routers/MainRouter';

// Css for icons
import '@fortawesome/fontawesome-pro/css/all.css';

const App = () => {
    return <MainRouter />;
};

export default App;
